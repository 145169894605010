var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var NI;$CLJS.LI=function(a){return $CLJS.n($CLJS.pB($CLJS.sd,$CLJS.rd,$CLJS.bl)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.MI=function(a,b){return $CLJS.Me($CLJS.Ad,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(J,S,X,W){return function(){for(var da=C;;){var ra=$CLJS.y(da);if(ra){var Ma=ra,sb=$CLJS.z(Ma);if(ra=$CLJS.y(function(Qa,Xa,Ja,Va,Ta,mb,dc,ed){return function Rh(hd){return new $CLJS.ie(null,function(sz,Jl,Wn,Ar,U_,Ov){return function(){for(;;){var Pv=
$CLJS.y(hd);if(Pv){if($CLJS.wd(Pv)){var Qv=$CLJS.ic(Pv),tz=$CLJS.D(Qv),Fk=$CLJS.le(tz);a:for(var Br=0;;)if(Br<tz){var gt=$CLJS.id(Qv,Br);gt=$CLJS.E.g(Ov,$CLJS.XD)||$CLJS.E.g(Wn,$CLJS.XD)||$CLJS.yE(Ov,gt)&&$CLJS.yE(Wn,gt);Fk.add(gt);Br+=1}else{Qv=!0;break a}return Qv?$CLJS.oe($CLJS.qe(Fk),Rh($CLJS.jc(Pv))):$CLJS.oe($CLJS.qe(Fk),null)}Fk=$CLJS.z(Pv);return $CLJS.ae($CLJS.E.g(Ov,$CLJS.XD)||$CLJS.E.g(Wn,$CLJS.XD)||$CLJS.yE(Ov,Fk)&&$CLJS.yE(Wn,Fk),Rh($CLJS.Hc(Pv)))}return null}}}(Qa,Xa,Ja,Va,Ta,mb,dc,
ed),null,null)}}(da,J,sb,Ma,ra,S,X,W)($CLJS.iE)))return $CLJS.bf.g(ra,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.LI($CLJS.KD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.LI($CLJS.KD(a)))}())};
NI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,$CLJS.Ne.j($CLJS.uE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.MD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.ml,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.MI($CLJS.K.g(k,m),$CLJS.K.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.OI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.PI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.QI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var RI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mr,$CLJS.gr],null)),SI=null,TI=0,UI=0;;)if(UI<TI){var Kia=SI.X(null,UI);$CLJS.xE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null)],null)]));UI+=1}else{var VI=$CLJS.y(RI);if(VI){var WI=VI;if($CLJS.wd(WI)){var XI=$CLJS.ic(WI),Lia=$CLJS.jc(WI),
Mia=XI,Nia=$CLJS.D(XI);RI=Lia;SI=Mia;TI=Nia}else{var Oia=$CLJS.z(WI);$CLJS.xE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null)],null)]));RI=$CLJS.B(WI);SI=null;TI=0}UI=0}else break}$CLJS.vE($CLJS.ir,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)]));
for(var YI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.yF],null)),ZI=null,$I=0,aJ=0;;)if(aJ<$I){var Pia=ZI.X(null,aJ);$CLJS.xE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));aJ+=1}else{var bJ=$CLJS.y(YI);if(bJ){var cJ=bJ;if($CLJS.wd(cJ)){var dJ=$CLJS.ic(cJ),Qia=$CLJS.jc(cJ),
Ria=dJ,Sia=$CLJS.D(dJ);YI=Qia;ZI=Ria;$I=Sia}else{var Tia=$CLJS.z(cJ);$CLJS.xE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));YI=$CLJS.B(cJ);ZI=null;$I=0}aJ=0}else break}
for(var eJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Kq,$CLJS.Eq,$CLJS.Gq],null)),fJ=null,gJ=0,hJ=0;;)if(hJ<gJ){var Uia=fJ.X(null,hJ);$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));hJ+=1}else{var iJ=$CLJS.y(eJ);if(iJ){var jJ=iJ;if($CLJS.wd(jJ)){var kJ=$CLJS.ic(jJ),Via=$CLJS.jc(jJ),Wia=kJ,Xia=$CLJS.D(kJ);eJ=Via;fJ=Wia;gJ=Xia}else{var Yia=
$CLJS.z(jJ);$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));eJ=$CLJS.B(jJ);fJ=null;gJ=0}hJ=0}else break}
$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.KE,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));
$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.QE,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));
for(var lJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.VE],null)),mJ=null,nJ=0,oJ=0;;)if(oJ<nJ){var Zia=mJ.X(null,oJ);$CLJS.vE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));oJ+=1}else{var pJ=$CLJS.y(lJ);if(pJ){var qJ=pJ;if($CLJS.wd(qJ)){var rJ=$CLJS.ic(qJ),$ia=$CLJS.jc(qJ),aja=rJ,bja=$CLJS.D(rJ);lJ=$ia;mJ=aja;nJ=bja}else{var cja=$CLJS.z(qJ);$CLJS.vE(cja,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));lJ=$CLJS.B(qJ);
mJ=null;nJ=0}oJ=0}else break}
for(var sJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,$CLJS.SE],null)),tJ=null,uJ=0,vJ=0;;)if(vJ<uJ){var dja=tJ.X(null,vJ);$CLJS.vE(dja,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));vJ+=1}else{var wJ=$CLJS.y(sJ);if(wJ){var xJ=wJ;if($CLJS.wd(xJ)){var yJ=$CLJS.ic(xJ),eja=$CLJS.jc(xJ),fja=yJ,gja=$CLJS.D(yJ);sJ=eja;tJ=fja;uJ=gja}else{var hja=$CLJS.z(xJ);$CLJS.vE(hja,$CLJS.H([$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));sJ=$CLJS.B(xJ);
tJ=null;uJ=0}vJ=0}else break}
for(var zJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null),AJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$E,$CLJS.tF,$CLJS.hF,$CLJS.aF],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var EJ=BJ.X(null,DJ);$CLJS.HF.v(EJ,$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,EJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,zJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eE],null)],null));DJ+=1}else{var FJ=$CLJS.y(AJ);if(FJ){var GJ=FJ;if($CLJS.wd(GJ)){var HJ=$CLJS.ic(GJ),ija=$CLJS.jc(GJ),jja=HJ,kja=$CLJS.D(HJ);AJ=ija;BJ=jja;CJ=kja}else{var IJ=$CLJS.z(GJ);$CLJS.HF.v(IJ,$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,IJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,zJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.eE],null)],null));AJ=$CLJS.B(GJ);BJ=null;CJ=0}DJ=0}else break}
$CLJS.HF.v($CLJS.cB,$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ij,$CLJS.Qu,$CLJS.Vw,$CLJS.PE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZF],null)],null));$CLJS.HF.v($CLJS.GE,$CLJS.Zr,$CLJS.qj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.GE],null),$CLJS.uD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.gD,$CLJS.oD],null)],null));
$CLJS.Y($CLJS.OI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.PI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Ij,$CLJS.Lq,$CLJS.yF,$CLJS.QE,$CLJS.KE,$CLJS.Iq,$CLJS.Eq,$CLJS.Kq,$CLJS.Gq,$CLJS.JE,$CLJS.VE,$CLJS.LE,$CLJS.SE,$CLJS.hF,$CLJS.aF,$CLJS.$E,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QI,$CLJS.Ii],null)],null));