var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var dU,eU,fU,hU,iU,jU,kU,lU,mU,nU;dU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);eU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);fU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.gU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);hU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);iU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);jU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
kU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);lU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);mU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);nU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(dU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.ts,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.ZT)));$CLJS.Y(hU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ij,$CLJS.HL,$CLJS.wM,$CLJS.BE,$CLJS.Ps,$CLJS.Nj,$CLJS.CO],null));
$CLJS.Y(kU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.oD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.oD,$CLJS.sr],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.el],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null));
$CLJS.Y(iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.BE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.YD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ej],null)],null)],null)],null));
$CLJS.Y(jU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.HL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,$CLJS.oD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.hK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.XJ],null)],null)],null));
$CLJS.Y(nU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.wM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cQ,$CLJS.WJ],null)],null)],null));$CLJS.Y(mU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ij],null),$CLJS.NT));
$CLJS.Y(eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null)],null)],null)],null));
$CLJS.Y(fU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Vi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$n,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eU],null)],null)],null)],null));
$CLJS.Y($CLJS.gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.oD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));