var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Qoa,YZ,Roa,$Z,a_,b_,y_,Uoa,Toa,Voa,Soa,E_,p_,G_,k_,Woa,r_;Qoa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};YZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Dd($CLJS.Yfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Roa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.vA,!0],null)),c=$CLJS.K.g(b,$CLJS.Xfa),d=$CLJS.K.g(b,$CLJS.vA);return $CLJS.y(a)?(b=$CLJS.wU(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v);x=YZ(x,$CLJS.Ra(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(YZ(u,
$CLJS.Ra(d)),k($CLJS.Hc(l)))}return null}},null,null)}(Qoa($CLJS.aA(a)))}()),$CLJS.n(c)?$CLJS.wU($CLJS.We(c,b)):b):null};$CLJS.ZZ=function(a){return $CLJS.fO.h($CLJS.z($CLJS.sK.h(a)))};$Z=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=$Z[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=$Z._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.table",a);}return a};
a_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=a_[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=a_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.card",a);}return a};b_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=b_[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=b_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.fields",a);}return a};
$CLJS.c_=function(a){return $CLJS.vY(a)?a:$CLJS.fD.h(a)};$CLJS.d_=function(a,b){return $Z($CLJS.c_(a),b)};$CLJS.e_=function(a,b){return b_($CLJS.c_(a),b)};$CLJS.f_=function(a,b){return a_($CLJS.c_(a),b)};
$CLJS.h_=function(a,b,c,d,e){var f=$CLJS.O(e);e=$CLJS.K.j(f,$CLJS.dZ,$CLJS.g_);f=$CLJS.K.g(f,$CLJS.cZ);if($CLJS.n($CLJS.n(f)?f:$CLJS.Ua(b)||$CLJS.td(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=e.o?e.o():e.call(null)),b=b.__mbcache,$CLJS.n(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.n(f))return f;d=d.h?d.h(c):d.call(null,c);e.v?e.v(b,a,c,d):e.call(null,b,a,c,d);return d}return d.h?d.h(c):d.call(null,c)};
$CLJS.i_=function(a,b,c){return $CLJS.h_(a,b,b,c,new $CLJS.h(null,2,[$CLJS.dZ,$CLJS.g_,$CLJS.cZ,!1],null))};
y_=function(a,b,c){var d=$CLJS.j_.j(a,b,c);return $CLJS.Gk.l($CLJS.H([$CLJS.Gl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.jD,$CLJS.wj],null)),function(){var e=$CLJS.PV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.jD,e,k_,!0],null):null}(),function(){var e=$CLJS.l_.v(a,b,c,$CLJS.m_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.n_,e],null):null}(),function(){var e=$CLJS.zz($CLJS.qi,$CLJS.tA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.qi,e],null):null}(),function(){var e=$CLJS.aK.h(d);return $CLJS.n(e)?
(e=$CLJS.Bd(e)?$CLJS.d_(a,e):"string"===typeof e?$CLJS.f_(a,$CLJS.GV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Qj,$CLJS.o_.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.MJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[p_,$CLJS.E.g(e,$CLJS.xY),$CLJS.q_,$CLJS.E.g(e,$CLJS.IY),r_,$CLJS.E.g(e,$CLJS.FY),$CLJS.s_,$CLJS.E.g(e,$CLJS.HY),Soa,$CLJS.E.g(e,$CLJS.lK),Toa,$CLJS.E.g(e,$CLJS.cK)],null):null}(),function(){var e=$CLJS.MY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.t_,e],null)}(),function(){var e=
$CLJS.zz($CLJS.JV,$CLJS.oF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.u_,$CLJS.Dd($CLJS.oG,e)&&!$CLJS.Dd($CLJS.nG,e)],null):null}(),$CLJS.Gl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.v_,$CLJS.w_,$CLJS.x_],null))]))};$CLJS.g_=function g_(a){switch(arguments.length){case 0:return g_.o();case 3:return g_.j(arguments[0],arguments[1],arguments[2]);case 4:return g_.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.g_.o=function(){return $CLJS.Se($CLJS.N)};$CLJS.g_.j=function(a,b){return $CLJS.K.g($CLJS.q(a),b)};$CLJS.g_.v=function(a,b,c,d){return $CLJS.zh.v(a,$CLJS.R,b,d)};$CLJS.g_.A=4;$CLJS.z_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.x_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.A_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.s_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.v_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.B_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.t_=new $CLJS.M(null,"selected","selected",574897764);Uoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Toa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Voa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.C_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.D_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Soa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
E_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.F_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);p_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);G_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);k_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.H_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.q_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.u_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.I_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.w_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Woa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.n_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.J_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.m_=new $CLJS.M(null,"long","long",-171452093);$CLJS.K_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.L_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);r_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.M_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();
$CLJS.N_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();
$CLJS.l_=function(){function a(f,k,l,m){var t=$CLJS.zz($CLJS.jD,$CLJS.nD)($CLJS.DD(l));if($CLJS.n(t))return t;try{return $CLJS.M_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Th($CLJS.eD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.tU(k)])),new $CLJS.h(null,2,[$CLJS.EE,f,$CLJS.xD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.O_=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.DD(f));if($CLJS.n(k))return k;try{return $CLJS.N_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Th($CLJS.eD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.tU(k)])),new $CLJS.h(null,3,[$CLJS.xD,f,$CLJS.EE,d,$CLJS.FK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.M_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Mz($CLJS.Ex);$CLJS.n($CLJS.Lz("metabase.lib.metadata.calculation",a))&&$CLJS.Kz("metabase.lib.metadata.calculation",a,$CLJS.uV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Voa,$CLJS.WA(c)])),null);return $CLJS.vd(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.N_.m(null,$CLJS.Oh,function(a,b,c){return Roa($CLJS.Gz($CLJS.l_.j(a,b,c),/[\(\)]/,""))});$CLJS.P_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.Xoa=function(){function a(d,e,f){return $CLJS.P_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Q_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();
$CLJS.R_=function(){function a(d,e,f){var k=$CLJS.DD(f);k=$CLJS.O(k);var l=$CLJS.K.g(k,$CLJS.oF),m=$CLJS.qi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Dd($CLJS.oG,l):l)?$CLJS.bj:null;if($CLJS.n(l))return l;k=$CLJS.tA.h(k);if($CLJS.n(k))return k;d=$CLJS.Q_.j(d,e,f);return $CLJS.Dz(d,$CLJS.fj)?d:$CLJS.fj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Q_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.KD(c)});$CLJS.Q_.m(null,$CLJS.aE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R_.j(a,b,c)});$CLJS.Q_.m(null,$CLJS.XH,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.KD(c):$CLJS.R_.j(a,b,d)});
$CLJS.S_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();
$CLJS.S_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.KJ,$CLJS.tA,$CLJS.R_.j(a,b,c),$CLJS.T,$CLJS.O_.j(a,b,c),$CLJS.jD,$CLJS.l_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Th($CLJS.eD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.WA(c)])),$CLJS.tU(d)])),new $CLJS.h(null,3,[$CLJS.EE,a,$CLJS.FK,b,$CLJS.xD,c],null),d);}throw e;}});
$CLJS.j_=function(){function a(e,f,k){return $CLJS.S_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.T_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();
$CLJS.Y(E_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[k_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,E_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[p_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.q_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[r_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.s_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[G_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Woa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.K_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.zj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.I_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.A_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,$CLJS.fF,$CLJS.ZE],null)],null)],null));
$CLJS.o_=function(){function a(d,e,f){return $CLJS.i_($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.T_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Th($CLJS.eD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.WA(k),$CLJS.tU(l)])),new $CLJS.h(null,3,[$CLJS.EE,d,$CLJS.FK,e,$CLJS.xD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.T_.m(null,$CLJS.Oh,function(a,b,c){return y_(a,b,c)});$CLJS.T_.m(null,$CLJS.SJ,function(a,b,c){return $CLJS.Gk.l($CLJS.H([y_(a,b,c),new $CLJS.h(null,1,[G_,$CLJS.E.g($CLJS.ZZ(a),$CLJS.Ki.h(c))],null)]))});
$CLJS.V_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();$CLJS.V_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.W_=function(){function a(f,k,l,m){m=$CLJS.Gk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.H_,$CLJS.IV()],null),m]));return $CLJS.V_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.FV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.X_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Si,$CLJS.fB],null),$CLJS.Si,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.WA(l)},e,a,b,c,d)}();$CLJS.X_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.X_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.V_.v(a,b,c,d)});
$CLJS.Y_=function(){function a(f,k,l,m){m=$CLJS.Gk.l($CLJS.H([$CLJS.Gk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.H_,$CLJS.IV()],null),new $CLJS.h(null,4,[$CLJS.F_,!0,$CLJS.L_,!0,$CLJS.B_,!0,$CLJS.J_,!0],null)])),m]));return $CLJS.X_.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.td(l);if(m){m=$CLJS.Vs.h(l);var t=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.bV,null,$CLJS.VU,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.i_($CLJS.mh.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.FV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.l_};