var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var apa,bpa,cpa,i0,j0,k0,dpa,epa,fpa,m0,n0,gpa;$CLJS.h0=function(a){var b=$CLJS.dd($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.mH($CLJS.H([$CLJS.sH])),"$"].join("")),a));return $CLJS.n(b)?b:a};apa=function(a,b){return a.isSame(b,"day")};bpa=function(a,b){return a.isSame(b,"month")};cpa=function(a,b){return a.isSame(b,"year")};
i0=function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.K.g(d,$CLJS.nj);d=$CLJS.K.g(d,$CLJS.e0);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.NH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.$oa)};j0=function(a){return a instanceof Date?$CLJS.NH.utc(a):a};k0=function(a,b,c){b="string"===typeof b?$CLJS.z(i0(b)):j0(b);return("string"===typeof c?$CLJS.z(i0(c)):j0(c)).diff(b,$CLJS.Xg(a))};
dpa=function(a,b){return k0.j?k0.j($CLJS.Rj,a,b):k0.call(null,$CLJS.Rj,a,b)};epa=function(a,b){if("string"===typeof a)return a;a=j0(a);var c=function(){if($CLJS.Dz(b,$CLJS.Ok))return $CLJS.b0;if($CLJS.Dz(b,$CLJS.Qk))return $CLJS.a0;if($CLJS.Dz(b,$CLJS.Mk))return $CLJS.f0;if($CLJS.Dz(b,$CLJS.Nk))return $CLJS.c0;if($CLJS.Dz(b,$CLJS.Lk))return $CLJS.d0;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.g0.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.l0=function(a){if($CLJS.n($CLJS.NH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.NH)($CLJS.h0(a),fpa);throw $CLJS.Uh("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.hj,a],null));};fpa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];m0=function m0(a,b){if("string"===typeof a){var d=i0(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=m0.g?m0.g(a,b):m0.call(null,a,b);return $CLJS.g0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=j0(a);return a.startOf($CLJS.Xg(b))};
n0=function n0(a,b,c){if("string"===typeof a){var e=i0(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=n0.j?n0.j(a,b,c):n0.call(null,a,b,c);return $CLJS.g0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=j0(a);return a.add(c,$CLJS.Xg(b))};gpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.o0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.nH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.p0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.$_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.hpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(apa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ipa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(bpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.jpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(cpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(k0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.q0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(m0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(n0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.r0=function r0(a){switch(arguments.length){case 1:return r0.h(arguments[0]);case 2:return r0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.r0);$CLJS.r0.h=function(a){return $CLJS.r0.g(a,$CLJS.N)};
$CLJS.r0.g=function(a,b){b=$CLJS.Gk.l($CLJS.H([$CLJS.N,$CLJS.fA(b)]));if(!$CLJS.n($CLJS.Z_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.NH.parseZone(a):"string"===typeof a?$CLJS.oH.g(a,b):$CLJS.pH.g(a,b)}return $CLJS.n(gpa.h(b))?a.local():a};$CLJS.r0.A=2;module.exports={coerce_to_timestamp:$CLJS.r0,coerce_to_time:$CLJS.l0};