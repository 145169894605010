var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,MF,cha,dha,eha,fha,gha,hha,iha,jha,OF,kha;$CLJS.IF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.JF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.KF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.LF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);MF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.NF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);OF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.PF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.xE($CLJS.Rw,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],null)],null)]));$CLJS.xE($CLJS.jF,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)],null)],null)]));
$CLJS.vE($CLJS.cF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.vE($CLJS.NE,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)],null)]));$CLJS.vE($CLJS.rF,$CLJS.H([$CLJS.Zr,$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));
$CLJS.vE($CLJS.Fj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)],null)]));$CLJS.oE($CLJS.Fj,$CLJS.aE);$CLJS.vE($CLJS.vF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.oE($CLJS.vF,$CLJS.aE);$CLJS.vE($CLJS.ui,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)],null)]));$CLJS.oE($CLJS.ui,$CLJS.aE);
$CLJS.Y(MF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.ts,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.vE($CLJS.UE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MF],null)]));$CLJS.oE($CLJS.UE,$CLJS.aE);
$CLJS.vE($CLJS.pF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));$CLJS.vE($CLJS.wF,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.vE($CLJS.uF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));
$CLJS.vE($CLJS.DF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.oE($CLJS.uF,$CLJS.aE);$CLJS.oE($CLJS.DF,$CLJS.aE);$CLJS.vE($CLJS.BF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)]));$CLJS.oE($CLJS.BF,$CLJS.aE);
$CLJS.vE($CLJS.OE,$CLJS.H([$CLJS.Zr,$CLJS.LC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gE],null)],null)]));$CLJS.Y(OF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.gr,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.el],null));$CLJS.Y($CLJS.JF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ui,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OF],null)],null));
$CLJS.QF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.bF,$CLJS.Rw,$CLJS.KF,!1,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Count of rows"),$CLJS.TE,$CLJS.HD("Count"),$CLJS.Hs,$CLJS.HD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.uF,$CLJS.IF,$CLJS.Cj,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Sum of ..."),$CLJS.TE,$CLJS.HD("Sum"),$CLJS.Hs,$CLJS.HD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.cF,$CLJS.IF,$CLJS.Cj,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Average of ..."),$CLJS.TE,$CLJS.HD("Average"),$CLJS.Hs,$CLJS.HD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.vF,$CLJS.IF,$CLJS.Cj,$CLJS.KF,!0,$CLJS.NF,$CLJS.eF,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Median of ..."),$CLJS.TE,$CLJS.HD("Median"),$CLJS.Hs,$CLJS.HD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.NE,$CLJS.IF,$CLJS.ur,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Number of distinct values of ..."),$CLJS.TE,$CLJS.HD("Distinct values"),$CLJS.Hs,$CLJS.HD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.DF,$CLJS.IF,$CLJS.Cj,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Cumulative sum of ..."),
$CLJS.TE,$CLJS.HD("Sum"),$CLJS.Hs,$CLJS.HD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.bF,$CLJS.jF,$CLJS.KF,!1,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Cumulative count of rows"),$CLJS.TE,$CLJS.HD("Count"),$CLJS.Hs,$CLJS.HD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.wF,$CLJS.IF,$CLJS.Cj,$CLJS.KF,
!0,$CLJS.NF,$CLJS.sF,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Standard deviation of ..."),$CLJS.TE,$CLJS.HD("SD"),$CLJS.Hs,$CLJS.HD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.ui,$CLJS.IF,$CLJS.Ji,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Minimum of ..."),$CLJS.TE,$CLJS.HD("Min"),$CLJS.Hs,$CLJS.HD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.bF,$CLJS.Fj,$CLJS.IF,$CLJS.Ji,$CLJS.KF,!0,$CLJS.NF,$CLJS.CE,$CLJS.LF,function(){return new $CLJS.h(null,3,[$CLJS.jD,$CLJS.HD("Maximum of ..."),$CLJS.TE,$CLJS.HD("Max"),$CLJS.Hs,$CLJS.HD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.PF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ij],null),$CLJS.cf.h($CLJS.bF),$CLJS.QF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Ii],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KF,$CLJS.tr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.NF,$CLJS.Ii],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.jd],null)],null));